// @tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

.ant-upload-wrapper {
  flex-grow: 2;
}

.ant-descriptions-item-label,
.ant-descriptions-item-content {
  vertical-align: top;
}

.desc-table {
  border-top: 1px solid rgba(5, 5, 5, .06);
  border-left: 1px solid rgba(5, 5, 5, .06);
  border-radius: 5px;

  .desc-row {
    border-bottom: 1px solid rgba(5, 5, 5, .06);
    display: flex;
    width: 100%;
  }

  .desc-col {
    border-right: 1px solid rgba(5, 5, 5, .06);
    padding: 15px;
  }

  .header-col {
    width: 40% !important;
    min-width: 40% !important;
    max-width: 40% !important;
    background-color: rgba(0, 0, 0, .02);
  }
}

.desc-row:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.action-icons {
  position: absolute;
  font-size: 12px;
  color: #636e72;
  opacity: 0;
  transition: all .3s;
  cursor: pointer;

  &.active-icon {
    display: inline;
    opacity: 1;
  }
}

.desc-row:first-child .desc-col:first-child {
  border-top-right-radius: 5px;
}

.desc-row:last-child .desc-col:first-child {
  border-bottom-right-radius: 5px;
}

.drag-item {
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 15px;
  background-clip: content-box;
  transition: all .3s;
}

.Typewriter__cursor {
  display: none;
}