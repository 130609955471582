@import '../../styles/colors.scss';

.chatbot-login-container {
  input {
    border: 1px solid $grey;
    outline: none !important;
    padding: 6px 10px;
    border-radius: 8px;
    width: 220px;
  }

  button {
    padding: 6px;
    border: 1px solid $blue-grey;
    border-radius: 8px;
    width: 220px;
    color: $blue;
    transition: all .3s;
  }

  button:hover {
    background-color: $blue;
    color: $white;
  }
}
